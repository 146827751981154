import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export function gsapFade() {
  gsap.utils.toArray('.js-fade').forEach((el) => {
    gsap.from(el, {
      duration: 0.6,
      stagger: {
        from: 'start',
        amount: 0.4,
      },
      opacity: 0,
      scrollTrigger: {
        trigger: el,
        start: 'top 65%',
        once: false,
        markers: false,
      },
    });
  });

  gsap.utils.toArray('.js-fadeUp').forEach((el) => {
    gsap.from(el, {
      duration: 0.6,
      stagger: {
        from: 'start',
        amount: 0.4,
      },
      opacity: 0,
      y: 30,
      scrollTrigger: {
        trigger: el,
        start: 'top 75%',
        once: false,
        markers: false,
      },
    });
  });

  gsap.utils.toArray('.js-fadeSlide').forEach((el) => {
    gsap.from(el, {
      duration: 0.6,
      stagger: {
        from: 'start',
        amount: 0.4,
      },
      opacity: 0,
      x: -30,
      scrollTrigger: {
        trigger: el,
        start: 'top 75%',
        once: false,
        markers: false,
      },
    });
  });
}
