import { slider } from './modules/slider';
import { gsapFade } from './modules/gsapFade';
slider();
gsapFade();

const heroRing01 = document.querySelector('.c-ringIndexHero01');
const introRing01 = document.querySelector('.c-ringIndexIntro01');
const introRing02 = document.querySelector('.c-ringIndexIntro02');
const worksRing01 = document.querySelector('.c-ringIndexWorks01');
const businessRing01 = document.querySelector('.c-ringIndexBusiness01');
const newsRing01 = document.querySelector('.c-ringIndexNews01');
const newsRing02 = document.querySelector('.c-ringIndexNews02');

window.addEventListener('load', () => {
  heroRing01.classList.add('is-view');
  introRing01.classList.add('is-view');
  introRing02.classList.add('is-view');
  worksRing01.classList.add('is-view');
  businessRing01.classList.add('is-view');
  newsRing01.classList.add('is-view');
  newsRing02.classList.add('is-view');
});
