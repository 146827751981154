// core version + navigation, pagination modules:
import Swiper, { EffectFade, Autoplay, Pagination, Navigation } from 'swiper';

export function slider() {
  if (document.querySelector('.js-heroSlider')) {
    // init Swiper:
    const swiper = new Swiper('.js-heroSlider', {
      // configure Swiper to use modules
      modules: [EffectFade, Autoplay, Pagination],
      effect: 'fade',
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.js-heroSliderPagination',
      },
    });
  }

  if (document.querySelector('.js-columnSlider')) {
    // init Swiper:
    const swiper = new Swiper('.js-columnSlider', {
      // configure Swiper to use modules
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      loop: true,
      // slidesPerGroup: 3,
      // centeredSlides: true,
      grabCursor: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.js-columnSliderNext',
        prevEl: '.js-columnSliderPrev',
      },
      breakpoints: {
        768: {
          slidesPerView: 3.5,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3.5,
          spaceBetween: 60,
        },
      },
    });
  }

  // 実績詳細ページ用スライド
  if (document.querySelector('.js-caseSlider')) {
    let swiperCaseThumbNum = '';
    const swiperCaseAll = document.querySelectorAll('.js-caseSlider .swiper-slide');
    const swiperCaseWrap = document.querySelector('.js-caseSlider .swiper-wrapper');
    const swiperCaseThumbAll = document.querySelectorAll('.js-caseSliderThumb .swiper-slide');
    const swiperCaseThumbWrap = document.querySelector('.js-caseSliderThumb .swiper-wrapper');

    // スライド数が2以上の場合、swiper起動
    if (swiperCaseThumbAll.length != 1) {
      if (swiperCaseThumbAll.length > 2 && swiperCaseThumbAll.length < 5) {
        // スライド数が3、4の時は複製回数1回
        // 複製理由としてはswiperの「swiper-slideの要素数 < slidePerView」になるとloopに不具合が発生するため
        for (let i = 0; i < swiperCaseThumbAll.length; i++) {
          const swiperCaseClone = swiperCaseAll[i].cloneNode(true);
          const swiperCaseThumbClone = swiperCaseThumbAll[i].cloneNode(true);
          swiperCaseWrap.appendChild(swiperCaseClone);
          swiperCaseThumbWrap.appendChild(swiperCaseThumbClone);
        }
      }
      // スライド数が2の時は複製回数2回
      if (swiperCaseThumbAll.length == 2) {
        // 2の時は3つ増やす
        let count = 1;
        while (count <= 5) {
          for (let i = 0; i < swiperCaseThumbAll.length; i++) {
            const swiperCaseClone = swiperCaseAll[i].cloneNode(true);
            const swiperCaseThumbClone = swiperCaseThumbAll[i].cloneNode(true);
            swiperCaseWrap.appendChild(swiperCaseClone);
            swiperCaseThumbWrap.appendChild(swiperCaseThumbClone);
          }
          count++;
          if (count == 3) {
            break;
          }
        }
      }

      const swiperCaseThumb = new Swiper('.js-caseSliderThumb', {
        modules: [Autoplay],
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        rewind: false,
        slideToClickedSlide: true,
        slidesPerView: 'auto',
        freeMode: true,
        watchSlidesProgress: true,
      });

      const swiperCase = new Swiper('.js-caseSlider', {
        // configure Swiper t use modules
        modules: [EffectFade],
        effect: 'fade',
        loop: true,
        thumbs: {
          swiper: swiperCaseThumb,
        },
      });

      // サムネイルをスライドした時にメインスライドを連動させる
      swiperCaseThumb.on('slideChange', () => {
        swiperCase.slideToLoop(swiperCaseThumb.realIndex);
      });
    } else {
      // スライド数が1つの時はサムネエリアを削除
      const swiperCaseThumbEle = document.querySelector('.js-caseSliderThumb');
      swiperCaseThumbEle.remove();
    }
  }

  // const thmubslider = new Swiper('.js-caseSliderThumb', {
  //   // configure Swiper to use modules
  //   modules: [Autoplay],
  //   loop: true,
  //   loopAdditionalSlides: 1,
  //   slidesPerView: 5,
  //   autoplay: {
  //     delay: 2500,
  //   },
  // });
}

export function spSlider() {
  window.addEventListener(
    'DOMContentLoaded',
    () => {
      if (document.querySelector('.js-sliderSp')) {
        // option は使い回すので別に書く
        const options = {
          modules: [EffectFade, Navigation, Autoplay],
          effect: 'fade',
          loop: true,
          slidesPerView: 1,
          navigation: {
            nextEl: '.js-spSlideNext',
            prevEl: '.js-spSlidePrev',
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        };
        const breakPoint = 767;
        let spSwiper;
        if (window.innerWidth <= breakPoint) {
          spSwiper = new Swiper('.js-sliderSp', options);
        } else {
          spSwiper = undefined;
        }
        window.addEventListener(
          'resize',
          () => {
            if (window.innerWidth <= breakPoint) {
              if (spSwiper) return;
              spSwiper = new Swiper('.js-sliderSp', options);
            } else {
              if (!spSwiper) return;
              spSwiper.destroy();
              spSwiper = undefined;
            }
          },
          false
        );
      }
    },
    false
  );
}

export function spFlowSlider() {
  window.addEventListener(
    'DOMContentLoaded',
    () => {
      if (document.querySelector('.js-sliderFlowSp')) {
        // option は使い回すので別に書く
        const options = {
          modules: [Navigation, Autoplay],
          // loop: true,
          slidesPerView: 1,
          navigation: {
            nextEl: '.js-spSlideNext',
            prevEl: '.js-spSlidePrev',
          },
          // autoplay: {
          //   delay: 5000,
          //   disableOnInteraction: false,
          // },
        };
        const breakPoint = 767;
        let spSwiper;
        if (window.innerWidth <= breakPoint) {
          spSwiper = new Swiper('.js-sliderFlowSp', options);
        } else {
          spSwiper = undefined;
        }
        window.addEventListener(
          'resize',
          () => {
            if (window.innerWidth <= breakPoint) {
              if (spSwiper) return;
              spSwiper = new Swiper('.js-sliderFlowSp', options);
            } else {
              if (!spSwiper) return;
              spSwiper.destroy();
              spSwiper = undefined;
            }
          },
          false
        );
      }
    },
    false
  );
}
